import React from "react"
import { Link } from "gatsby"
import Head from "../components/head"
import Header from "../components/header"
import Nav from "../components/nav"
import styles from "../styles/global.css"
import why from "../styles/why.css"
import services from "../styles/services.css"
import media from "../styles/why_media.css"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { LoginHeader } from "../components/nav";
import { SocialHeader } from "../components/social";

export function Banner_img() {
  return <StaticImage src="../../static/images/banner3.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Why Happy Mango? - Happy Mango" />
    <div className="main main_why">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a className="current_menu" rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
      </div>
      <Head />

      <div className="list_div">
        <ul className="list_ul">
          <div id="Solution" className="div_height"></div>
          <li className="list_li">
            <div className="content_div">
              <div className="content_div_son">
                <p>Complete</p>
                <h1>Complete Solution</h1>
              </div>
              <div className="icon_div">
                <label className="full-page-accordion-arrow checkbox_input_label"></label>
              </div>
            </div>
            <div className="content_black">
              <div className="content_black_son">
                <ul>
                  <li>
                    Online processing from customer intake to payment management
                  </li>
                  <li>
                    Full range of products from depository accounts to business
                    loans
                  </li>
                  <li>
                    Seamless integration of financial products and coaching
                    services
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li className="list_li">
            <div className="content_div">
              <div className="content_div_son">
                <p>Fast</p>
                <h1>Easy Implementation</h1>
              </div>
              <div className="icon_div">
                <label className="full-page-accordion-arrow checkbox_input_label"></label>
              </div>
            </div>
            <div className="content_black">
              <div className="content_black_son">
                <ul>
                  <li>
                    Customized interface on desktop, mobile devices, and smart
                    phones
                  </li>
                  <li>
                    Built-in tools for quickly launching new financial products
                  </li>
                  <li>
                    Multi-channel communication for engaging with customers
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li className="list_li">
            <div className="content_div">
              <div className="content_div_son">
                <p>Flexible</p>
                <h1>Unique Functions</h1>
              </div>
              <div className="icon_div">
                <label className="full-page-accordion-arrow checkbox_input_label"></label>
              </div>
            </div>
            <div className="content_black">
              <div className="content_black_son">
                <ul>
                  <li>
                    Cash flow assessment to complement or supplement credit
                    metrics
                  </li>
                  <li>
                    Real-time reporting on customers, operations, portfolio
                    performance
                  </li>
                  <li>Free Personal Finance Management (PFM) tools</li>
                </ul>
              </div>
            </div>
          </li>

          <li className="list_li">
            <div className="content_div">
              <div className="content_div_son">
                <p>Intelligent</p>
                <h1>Affordable Cost</h1>
              </div>
              <div className="icon_div">
                <label className="full-page-accordion-arrow checkbox_input_label"></label>
              </div>
            </div>
            <div className="content_black">
              <div className="content_black_son">
                <ul>
                  <li>
                    Subscription based short-term contract that minimizes risk
                    and cost
                  </li>
                  <li>
                    Intuitive interface and full functionality saving staff time
                  </li>
                  <li>
                    Automation empowering a single operator to run a full-scale
                    program
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li className="list_li">
            <div className="content_div">
              <div className="content_div_son">
                <p>Intelligent</p>
                <h1>Continuous Innovation</h1>
              </div>
              <div className="icon_div">
                <label className="full-page-accordion-arrow checkbox_input_label"></label>
              </div>
            </div>
            <div className="content_black">
              <div className="content_black_son">
                <ul>
                  <li>Two-week release cycle</li>
                  <li>
                    Latest solution for payments, account aggregation, and data
                    analytics
                  </li>
                  <li>Onboarding service with ongoing customer support</li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <footer className="footer_position">
        <Footer />
      </footer>
      <script src="/js/why.js"></script>
    </div>
  </>
)
